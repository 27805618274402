// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".animated_root__1e\\+vW {\n  flex-grow: 5;\n  flex-shrink: 5;\n  display: flex;\n}\n@media screen and (max-width: 1020px) {\n  .animated_root__1e\\+vW {\n    display: flex;\n    flex-grow: 0;\n    flex-shrink: 0;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/animatedRoutes/animated.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;EACA,aAAA;AACF;AAAE;EAJF;IAKI,aAAA;IACA,YAAA;IACA,cAAA;EAGF;AACF","sourcesContent":[".root {\r\n  flex-grow: 5;\r\n  flex-shrink: 5;\r\n  display: flex;\r\n  @media screen and (max-width: 1020px) {\r\n    display: flex;\r\n    flex-grow: 0;\r\n    flex-shrink: 0;\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "animated_root__1e+vW"
};
export default ___CSS_LOADER_EXPORT___;
