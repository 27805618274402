// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search_root__KI8bc {\n  display: flex;\n  justify-content: flex-start;\n  width: 300px;\n  border: solid 1px hsla(0, 0%, 83%, 0.5);\n  border-radius: 5px;\n}\n\n.search_searchInput__afGgN {\n  background-color: transparent;\n  background-image: none;\n  border: 0px solid black;\n  padding: 10px;\n  font-size: 18px;\n  flex: 1 1;\n  color: white;\n  background-color: hsla(0, 0%, 83%, 0.05);\n  width: 10%;\n}\n\n.search_searchInput__afGgN:focus {\n  outline: none;\n}\n\n.search_searchButton__t5crX {\n  background-color: hsla(0, 0%, 83%, 0.15);\n  color: rgb(146, 144, 144);\n  color: white;\n  border: 0px;\n  font-size: 15px;\n}", "",{"version":3,"sources":["webpack://./src/components/search/search.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,2BAAA;EACA,YAAA;EACA,uCAAA;EACA,kBAAA;AACF;;AACA;EACE,6BAAA;EACA,sBAAA;EACA,uBAAA;EACA,aAAA;EACA,eAAA;EACA,SAAA;EACA,YAAA;EACA,wCAAA;EACA,UAAA;AAEF;;AACA;EACE,aAAA;AAEF;;AACA;EACE,wCAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AAEF","sourcesContent":[".root {\n  display: flex;\n  justify-content: flex-start ;\n  width: 300px;\n  border: solid 1px hsla(0, 0%, 83%, 0.5);\n  border-radius: 5px;\n}\n.searchInput {\n  background-color: transparent;\n  background-image: none;\n  border: 0px solid black;\n  padding: 10px;\n  font-size: 18px;\n  flex: 1;\n  color: white;\n  background-color: hsla(0, 0%, 83%, 0.05);\n  width: 10%;\n}\n\n.searchInput:focus {\n  outline: none;\n}\n\n.searchButton {\n  background-color: hsla(0, 0%, 83%, 0.15);\n  color: rgb(146, 144, 144);\n  color: white;\n  border: 0px;\n  font-size: 15px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "search_root__KI8bc",
	"searchInput": "search_searchInput__afGgN",
	"searchButton": "search_searchButton__t5crX"
};
export default ___CSS_LOADER_EXPORT___;
