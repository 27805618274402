// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mediaButton_mediaNavButton__G2LBU {\n  position: absolute;\n  top: 0;\n  right: 0;\n  text-decoration: none;\n  color: white;\n  font-size: 30px;\n  padding: 10px 20px;\n  display: none;\n}\n@media screen and (max-width: 1020px) {\n  .mediaButton_mediaNavButton__G2LBU {\n    display: block;\n  }\n}\n@media screen and (max-width: 567px) {\n  .mediaButton_mediaNavButton__G2LBU {\n    padding: 5px 10px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/common/mediaButton.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,MAAA;EACA,QAAA;EACA,qBAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;AACJ;AAAI;EATJ;IAUM,cAAA;EAGJ;AACF;AAFI;EAZJ;IAeM,iBAAA;EAGJ;AACF","sourcesContent":[".mediaNavButton {\r\n    position: absolute;\r\n    top: 0;\r\n    right: 0;\r\n    text-decoration: none;\r\n    color: white;\r\n    font-size: 30px;\r\n    padding: 10px 20px;\r\n    display: none;\r\n    @media screen and (max-width: 1020px) {\r\n      display: block;\r\n    }\r\n    @media screen and (max-width: 567px) {\r\n      // font-size: 20px;\r\n      // padding: 2px;\r\n      padding: 5px 10px;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mediaNavButton": "mediaButton_mediaNavButton__G2LBU"
};
export default ___CSS_LOADER_EXPORT___;
