// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".books_root__OU9Am {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-items: start;\n  flex-direction: column;\n  gap: 30px;\n  background-color: rgba(0, 0, 0, 0.85);\n  overflow-x: hidden;\n  padding-top: 40px;\n}\n@media screen and (max-width: 1020px) {\n  .books_root__OU9Am {\n    height: 100vh;\n  }\n}\n\n.books_root__OU9Am::-webkit-scrollbar {\n  display: none;\n}\n\n@keyframes books_enter__uhR4U {\n  0% {\n    opacity: 0;\n    transform: translateY(-100%);\n  }\n  90% {\n    opacity: 0.5;\n  }\n  100% {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/books/books.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,sBAAA;EACA,SAAA;EACA,qCAAA;EACA,kBAAA;EACA,iBAAA;AACF;AACE;EAXF;IAYI,aAAA;EAEF;AACF;;AACA;EACE,aAAA;AAEF;;AACA;EACE;IACE,UAAA;IACA,4BAAA;EAEF;EACA;IACE,YAAA;EACF;EACA;IACE,UAAA;IACA,wBAAA;EACF;AACF","sourcesContent":[".root {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-items: start;\n  flex-direction: column;\n  gap: 30px;\n  background-color: rgba(0, 0, 0, 0.85);\n  overflow-x: hidden;\n  padding-top: 40px;\n\n  @media screen and (max-width: 1020px) {\n    height: 100vh;\n  }\n}\n\n.root::-webkit-scrollbar {\n  display: none;\n}\n\n@keyframes enter {\n  0% {\n    opacity: 0;\n    transform: translateY(-100%);\n  }\n\n  90% {\n    opacity: 0.5;\n  }\n  100% {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "books_root__OU9Am",
	"enter": "books_enter__uhR4U"
};
export default ___CSS_LOADER_EXPORT___;
