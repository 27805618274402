// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/images/background/Book.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/images/background/Ninja.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_App__2\\+VHF {\n  text-align: center;\n  width: 100vw;\n  color: #f2f2f2;\n  display: flex;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-color: #080605;\n  background-repeat: no-repeat;\n  background-position: top center;\n  background-size: cover;\n  height: 100vh;\n}\n@media screen and (max-width: 1020px) {\n  .App_App__2\\+VHF {\n    flex-direction: column;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    background-position: center 40%;\n    background-repeat: repeat-y;\n  }\n}\n\n.App_mediaMavButton__dCflY {\n  color: red;\n  position: absolute;\n  top: 0;\n  left: 0;\n}", "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,mDAAA;EACA,yBAAA;EACA,4BAAA;EACA,+BAAA;EACA,sBAAA;EACA,aAAA;AACF;AACE;EAZF;IAaI,sBAAA;IACA,mDAAA;IACA,+BAAA;IACA,2BAAA;EAEF;AACF;;AACA;EACE,UAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;AAEF","sourcesContent":[".App {\n  text-align: center;\n  width: 100vw;\n  color: #f2f2f2;\n  display: flex;\n  background: url('../public/images/background/Book.jpg');\n  background-color: #080605;\n  background-repeat: no-repeat;\n  background-position: top center;\n  background-size: cover;\n  height: 100vh;\n\n  @media screen and (max-width: 1020px) {\n    flex-direction: column;\n    background: url('../public/images/background/Ninja.jpg');\n    background-position: center 40%;\n    background-repeat:repeat-y\n  }\n}\n\n.mediaMavButton {\n  color: red;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": "App_App__2+VHF",
	"mediaMavButton": "App_mediaMavButton__dCflY"
};
export default ___CSS_LOADER_EXPORT___;
